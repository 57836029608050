// import des images PRESTIGE

import boatsPrestige from '../assets/images/prestige/Header/Boats.jpg';
import teamPrestige from '../assets/images/prestige/Header/Team.jpg';
import accountPrestige from '../assets/images/prestige/Header/Account.jpg';
import addUserPrestige from '../assets/images/prestige/Header/AddUser.jpg';
import cartPrestige from '../assets/images/prestige/Header/Cart.jpg';
import contactPrestige from '../assets/images/prestige/Header/Contact.jpg';
import dashboardPrestige from '../assets/images/prestige/Header/Dashboard.jpg';
import deliveryPrestige from '../assets/images/prestige/Header/Delivery.jpg';
import faqPrestige from '../assets/images/prestige/Header/Faq.jpg';
import loisirsPrestige from '../assets/images/prestige/Header/Loisirs.jpg';
import registerProPrestige from '../assets/images/prestige/Header/RegisterPro.jpg';
import techniquePrestige from '../assets/images/prestige/Header/Technique.jpg';
import vieABordPrestige from '../assets/images/prestige/Header/VieABord.jpg';
import sliderInterieurPrestige from '../assets/images/prestige/Slider/VieABord.jpg';
import sliderLoisirPrestige from '../assets/images/prestige/Slider/Loisir.jpg';
import sliderTechniquePrestige from '../assets/images/prestige/Slider/Technique.jpg';
import logoPrestige from '../assets/images/prestige/Logo/iconPrestige.png';
import logoAllsetPrestige from '../assets/images/prestige/Logo/LogoAllSet_Prestige.svg';

// import des images LAGOON

import boatsLagoon from '../assets/images/lagoon/Header/Boats.jpg';
import teamLagoon from '../assets/images/lagoon/Header/Team.jpg';
import accountLagoon from '../assets/images/lagoon/Header/Account.jpg';
import addUserLagoon from '../assets/images/lagoon/Header/AddUser.jpg';
import cartLagoon from '../assets/images/lagoon/Header/Cart.jpg';
import contactLagoon from '../assets/images/lagoon/Header/Contact.jpg';
import dashboardLagoon from '../assets/images/lagoon/Header/Dashboard.jpg';
import deliveryLagoon from '../assets/images/lagoon/Header/Delivery.jpg';
import faqLagoon from '../assets/images/lagoon/Header/Faq.jpg';
import loisirsLagoon from '../assets/images/lagoon/Header/Loisirs.jpg';
import registerProLagoon from '../assets/images/lagoon/Header/RegisterPro.jpg';
import techniqueLagoon from '../assets/images/lagoon/Header/Technique.jpg';
import vieABordLagoon from '../assets/images/lagoon/Header/VieABord.jpg';
import offrePromo from '../assets/images/lagoon/Header/OffrePromo.jpg';
import sliderInterieurLagoon from '../assets/images/lagoon/Slider/VieABord.jpg';
import sliderLoisirLagoon from '../assets/images/lagoon/Slider/Loisir.jpg';
import sliderTechniqueLagoon from '../assets/images/lagoon/Slider/Technique.jpg';
import logoLagoon from '../assets/images/lagoon/Logo/iconLagoon.png';
import logoAllsetLagoon from '../assets/images/lagoon/Logo/LogoAllSet_Lagoon.svg';

// import des images EXCESS

import boatsExcess from '../assets/images/excess/Header/Boats.jpg';
import teamExcess from '../assets/images/excess/Header/Team.jpg';
import accountExcess from '../assets/images/excess/Header/Account.jpg';
import addUserExcess from '../assets/images/excess/Header/AddUser.jpg';
import cartExcess from '../assets/images/excess/Header/Cart.jpg';
import contactExcess from '../assets/images/excess/Header/Contact.jpg';
import dashboardExcess from '../assets/images/excess/Header/Dashboard.jpg';
import deliveryExcess from '../assets/images/excess/Header/Delivery.jpg';
import faqExcess from '../assets/images/excess/Header/Faq.jpg';
import loisirsExcess from '../assets/images/excess/Header/Loisirs.jpg';
import registerProExcess from '../assets/images/excess/Header/RegisterPro.jpg';
import techniqueExcess from '../assets/images/excess/Header/Technique.jpg';
import vieABordExcess from '../assets/images/excess/Header/VieABord.jpg';
import offrePromoExcess from '../assets/images/excess/Header/OffrePromo.png';
import sliderInterieurExcess from '../assets/images/excess/Slider/VieABord.jpg';
import sliderLoisirExcess from '../assets/images/excess/Slider/Loisir.jpg';
import sliderTechniqueExcess from '../assets/images/excess/Slider/Technique.jpg';
import logoExcess from '../assets/images/excess/Logo/iconExcess.png';
import logoAllsetExcess from '../assets/images/excess/Logo/LogoAllSet_Excess.svg';

// import des images ALLSET
import boatsAllset from '../assets/images/allset/Header/Boats.jpg';
import teamAllset from '../assets/images/allset/Header/Team.jpg';
import accountAllset from '../assets/images/allset/Header/Account.jpg';
import addUserAllset from '../assets/images/allset/Header/AddUser.jpg';
import cartAllset from '../assets/images/allset/Header/Cart.jpg';
import contactAllset from '../assets/images/allset/Header/Contact.jpg';
import dashboardAllset from '../assets/images/allset/Header/Dashboard.jpg';
import deliveryAllset from '../assets/images/allset/Header/Delivery.jpg';
import faqAllset from '../assets/images/allset/Header/Faq.jpg';
import loisirsAllset from '../assets/images/allset/Header/Loisirs.jpg';
import registerProAllset from '../assets/images/allset/Header/RegisterPro.jpg';
import techniqueAllset from '../assets/images/allset/Header/Technique.jpg';
import vieABordAllset from '../assets/images/allset/Header/VieABord.jpg';
import sliderInterieurAllset from '../assets/images/allset/Slider/VieABord.jpg';
import sliderLoisirAllset from '../assets/images/allset/Slider/Loisir.jpg';
import sliderTechniqueAllset from '../assets/images/allset/Slider/Technique.jpg';
import logoAllset from '../assets/images/allset/Logo/LogoAllSet_Original.svg';

function imagesOfBrand(brand, composantName, titleCat) {
  switch (brand) {
    case 'all-set':
      switch (composantName) {
        case 'Boats':
          return boatsAllset;
        case 'Account':
          return accountAllset;
        case 'AddUser':
          return addUserAllset;
        case 'Cart':
          return cartAllset;
        case 'Contact':
          return contactAllset;
        case 'Dashboard':
          return dashboardAllset;
        case 'DashboardPro':
          return dashboardAllset;
        case 'Delivery':
          return deliveryAllset;
        case 'Faq':
          return faqAllset;
        case 'RegisterPro':
          return registerProAllset;
        case 'Team':
          return teamAllset;
        case 'CategoryPacks':
          switch (titleCat) {
            case 'Technique':
              return techniqueAllset;
            case 'Technical':
              return techniqueAllset;
            case 'Loisirs':
              return loisirsAllset;
            case 'Leisure':
              return loisirsAllset;
            case 'Vie à bord':
              return vieABordAllset;
            case 'Life on board':
              return vieABordAllset;
            default:
              return false;
          }
        case 'sliderInterieur':
          return sliderInterieurAllset;
        case 'sliderLoisir':
          return sliderLoisirAllset;
        case 'sliderTechnique':
          return sliderTechniqueAllset;
        case 'catHomepageInterieur':
          return sliderInterieurAllset;
        case 'catHomepageLoisir':
          return sliderLoisirAllset;
        case 'catHomepageTechnique':
          return sliderTechniqueAllset;
        case 'headerUnloggedLogoAllset':
          return logoAllset;
        case 'headerLogoAllset':
          return logoAllset;
        default:
          return false;
      }

    case 'prestige':
      switch (composantName) {
        case 'Boats':
          return boatsPrestige;
        case 'Account':
          return accountPrestige;
        case 'AddUser':
          return addUserPrestige;
        case 'Cart':
          return cartPrestige;
        case 'Contact':
          return contactPrestige;
        case 'Dashboard':
          return dashboardPrestige;
        case 'DashboardPro':
          return dashboardPrestige;
        case 'Delivery':
          return deliveryPrestige;
        case 'Faq':
          return faqPrestige;
        case 'RegisterPro':
          return registerProPrestige;
        case 'Team':
          return teamPrestige;
        case 'CategoryPacks':
          switch (titleCat) {
            case 'Technique':
              return techniquePrestige;
            case 'Technical':
              return techniquePrestige;
            case 'Loisirs':
              return loisirsPrestige;
            case 'Leisure':
              return loisirsPrestige;
            case 'Vie à bord':
              return vieABordPrestige;
            case 'Life on board':
              return vieABordPrestige;
            default:
              return false;
          }
        case 'sliderInterieur':
          return sliderInterieurPrestige;
        case 'sliderLoisir':
          return sliderLoisirPrestige;
        case 'sliderTechnique':
          return sliderTechniquePrestige;
        case 'catHomepageInterieur':
          return sliderInterieurPrestige;
        case 'catHomepageLoisir':
          return sliderLoisirPrestige;
        case 'catHomepageTechnique':
          return sliderTechniquePrestige;
        case 'headerUnloggedLogoBrand':
          return logoPrestige;
        case 'headerUnloggedLogoAllset':
          return logoAllset;
        case 'headerLogoBrand':
          return logoPrestige;
        case 'headerLogoAllset':
          return logoAllsetPrestige;
        default:
          return false;
      }

    case 'lagoon':
      switch (composantName) {
        case 'Boats':
          return boatsLagoon;
        case 'Account':
          return accountLagoon;
        case 'AddUser':
          return addUserLagoon;
        case 'Cart':
          return cartLagoon;
        case 'Contact':
          return contactLagoon;
        case 'Dashboard':
          return dashboardLagoon;
        case 'DashboardPro':
          return dashboardLagoon;
        case 'Delivery':
          return deliveryLagoon;
        case 'Faq':
          return faqLagoon;
        case 'RegisterPro':
          return registerProLagoon;
        case 'Team':
          return teamLagoon;
        case 'CategoryPacks':
          switch (titleCat) {
            case 'Technique':
              return techniqueLagoon;
            case 'Technical':
              return techniqueLagoon;
            case 'Loisirs':
              return loisirsLagoon;
            case 'Leisure':
              return loisirsLagoon;
            case 'Vie à bord':
              return vieABordLagoon;
            case 'Life on board':
              return vieABordLagoon;
            default:
              return false;
          }
        case 'sliderInterieur':
          return sliderInterieurLagoon;
        case 'sliderLoisir':
          return sliderLoisirLagoon;
        case 'sliderTechnique':
          return sliderTechniqueLagoon;
        case 'catHomepageInterieur':
          return sliderInterieurLagoon;
        case 'catHomepageLoisir':
          return sliderLoisirLagoon;
        case 'catHomepageTechnique':
          return sliderTechniqueLagoon;
        case 'headerUnloggedLogoBrand':
          return logoLagoon;
        case 'headerUnloggedLogoAllset':
          return logoAllset;
        case 'headerLogoBrand':
          return logoLagoon;
        case 'headerLogoAllset':
          return logoAllsetLagoon;
        case 'OffrePromo':
          return offrePromo;
        default:
          return false;
      }

    case 'excess':
      switch (composantName) {
        case 'Boats':
          return boatsExcess;
        case 'Account':
          return accountExcess;
        case 'AddUser':
          return addUserExcess;
        case 'Cart':
          return cartExcess;
        case 'Contact':
          return contactExcess;
        case 'Dashboard':
          return dashboardExcess;
        case 'DashboardPro':
          return dashboardExcess;
        case 'Delivery':
          return deliveryExcess;
        case 'Faq':
          return faqExcess;
        case 'RegisterPro':
          return registerProExcess;
        case 'Team':
          return teamExcess;
        case 'CategoryPacks':
          switch (titleCat) {
            case 'Technique':
              return techniqueExcess;
            case 'Technical':
              return techniqueExcess;
            case 'Loisirs':
              return loisirsExcess;
            case 'Leisure':
              return loisirsExcess;
            case 'Vie à bord':
              return vieABordExcess;
            case 'Life on board':
              return vieABordExcess;
            default:
              return false;
          }
        case 'sliderInterieur':
          return sliderInterieurExcess;
        case 'sliderLoisir':
          return sliderLoisirExcess;
        case 'sliderTechnique':
          return sliderTechniqueExcess;
        case 'catHomepageInterieur':
          return sliderInterieurExcess;
        case 'catHomepageLoisir':
          return sliderLoisirExcess;
        case 'catHomepageTechnique':
          return sliderTechniqueExcess;
        case 'headerUnloggedLogoBrand':
          return logoExcess;
        case 'headerUnloggedLogoAllset':
          return logoAllset;
        case 'headerLogoBrand':
          return logoExcess;
        case 'headerLogoAllset':
          return logoAllsetExcess;
        case 'OffrePromo':
          return offrePromoExcess;
        default:
          return false;
      }

    default:
      return false;
  }

  // Pour ajouter une autre marque, dupliquer l'ensemble du case prestige
}

export default imagesOfBrand;
