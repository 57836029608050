import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { boatSelected, userConnected } from '../../recoil';

export function LoggedRoute({ children }) {
  const user = useRecoilValue(userConnected);
  return user && user.active ? children : <Navigate to="/signin" />;
}

export function UnloggedRoute({ children }) {
  const user = useRecoilValue(userConnected);
  return user && user.active ? <Navigate to="/" /> : children;
}

export function AgentRoute({ children }) {
  const user = useRecoilValue(userConnected);
  return user && user.agent ? children : <Navigate to="/" />;
}

export function BoatSelected({ children }) {
  const boat = useRecoilValue(boatSelected);
  return boat ? children : <Navigate to="/" />;
}
