import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { notifcation } from '../../recoil';
import styles from './Notification.module.scss';

function Notification() {
  const notif = useRecoilValue(notifcation);
  const [test, setTest] = useState('');

  useEffect(() => {
    if (notif) {
      switch (notif.type) {
        case 'warning':
          setTest([{ ...notif, colorClass: styles.warning, startTime: new Date() }, ...test]);
          break;

        case 'danger':
          setTest([{ ...notif, colorClass: styles.danger, startTime: new Date() }, ...test]);
          break;

        case 'success':
        default:
          setTest([{ ...notif, colorClass: styles.success, startTime: new Date() }, ...test]);
          break;
      }
    }
  }, [notif]);

  function animationTimeSinceStart(startTime) {
    return ((new Date().getTime() - startTime.getTime()) / 1000);
  }

  return (
    <div className={styles.notificationBox}>
      {

      test
      && test.map((t) => ((animationTimeSinceStart(t.startTime) < 5) && (
        <div
          key={Math.floor(Math.random() * 999)}
          className={`${styles.notification} ${t.colorClass}`}
          style={{ animationDuration: `${`${5 - animationTimeSinceStart(t.startTime)}s`}` }}
        >
          <i className="fa-solid fa-circle-info" />
          <p className={styles.text}>
            {t.text}
          </p>
        </div>
      )

      ))
      }
    </div>
  );
}

export default Notification;
