import { NavLink, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import styles from './Web.module.scss';
import { boatSelected, brandSelected, dateMaxOffreExcess, languageSelected, numbersPacksInCart, offreExcess, resumeCart, userBoats, userConnected } from '../../../../recoil';
import { logout } from '../../../../utils/Auth';
import BoatMenuNav from './components/BoatMenuNav';
import logoFlagFr from '../../../../assets/images/global/Logo/fr_flag.png';
import logoFlagEn from '../../../../assets/images/global/Logo/en_flag.png';
import imagesOfBrand from '../../../../utils/images';

function Web() {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userConnected);
  const [cart, setResumeCart] = useRecoilState(resumeCart);
  const [boatSelect, setBoatSelected] = useRecoilState(boatSelected);
  const [boats, setBoats] = useRecoilState(userBoats);
  const [lang, setLang] = useRecoilState(languageSelected);
  const brand = useRecoilValue(brandSelected);
  const quantityPacks = useRecoilValue(numbersPacksInCart);
  const [animeCart, setAnimeCart] = useState(styles.numbersCart);
  const [numberCartPadding, setnumberCartPadding] = useState('');
  const promoExcess = useRecoilValue(offreExcess);

  function disconnect() {
    logout();
    setBoatSelected(null);
    setResumeCart(null);
    setUser(null);
    navigate('/');
  }

  useEffect(() => {
    if (quantityPacks > 0 && quantityPacks < 2) {
      setnumberCartPadding(styles.numbersCartPaddingOne);
    } else if (quantityPacks > 1 && quantityPacks < 10) {
      setnumberCartPadding(styles.numbersCartPaddingTwo);
    } else {
      setnumberCartPadding(styles.numbersCartPaddingThree);
    }
    setAnimeCart(styles.numbersCartInsert);
    const timeout = setTimeout(() => {
      setAnimeCart(styles.numbersCart);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [quantityPacks]);

  return (
    <header className={styles.header}>
      <ul className={styles.menu1}>
        {brand !== 'all-set'
          ? (brand !== 'localhost'
            ? (brand !== 'test'
        && (
        <NavLink to={boatSelect ? '/top-sales' : '/'}>
          <li><img className={styles.logoBrand} src={imagesOfBrand(brand, 'headerLogoBrand').toString()} alt="logo prestige" /></li>
        </NavLink>
        )) : '') : ''}
        <li className={styles.menuYachtParent}>
          <NavLink to="/">
            {lang === 'fr' ? 'Mes Yachts' : 'My Yachts'}
          </NavLink>
          {boats.length > 0
            ? (
              <ul className={`${styles.subMenuFirst} ${styles.subMenuFirstWidth} ${user.agent ? styles.headerPro : ''} ${window.location.hostname.split('.')[0] === 'prestigetest' ? styles.headerTest : (window.location.hostname.split('.')[0] === 'test' ? styles.headerTest : '')} ${promoExcess ? (user.agent ? styles.headerPromo : styles.headerPromoNoAgent) : ''}`}>
                {boats.map((b) => (<li key={b.id}><BoatMenuNav boat={b} /></li>)).slice(-4).reverse()}
                <li>
                  <button onClick={() => navigate('/')} className="btn btn-secondary m-auto">{boats.length > 4 ? (lang === 'fr' ? 'Voir plus' : 'See more') : (lang === 'fr' ? 'Ajouter un yacht' : 'Add a yacht')}</button>
                </li>
              </ul>
            )
            : '' }
        </li>
        {boatSelect
        && (

        <li className={styles.menuParent}>
          <NavLink to="/top-sales">{lang === 'fr' ? 'Produits' : 'Products'}</NavLink>
          <ul className={`${styles.subMenu} ${styles.subMenuOneWidth} ${user.agent ? styles.headerPro : ''} ${window.location.hostname.split('.')[0] === 'prestigetest' ? styles.headerTest : (window.location.hostname.split('.')[0] === 'test' ? styles.headerTest : '')} ${promoExcess ? (user.agent ? styles.headerPromo : styles.headerPromoNoAgent) : ''}`}>
            <NavLink to={{ pathname: '/packs/life-on-board' }} state={{ title: lang === 'fr' ? 'Vie à bord' : 'Life on board' }}>
              <li>{lang === 'fr' ? 'Vie à bord' : 'Life on board'}</li>
            </NavLink>
            <NavLink to={{ pathname: '/packs/leisure' }} state={{ title: lang === 'fr' ? 'Loisirs' : 'Leisure' }}>
              <li>{lang === 'fr' ? 'Loisirs' : 'Leisure'}</li>
            </NavLink>
            <NavLink to={{ pathname: '/packs/technical-equipment' }} state={{ title: lang === 'fr' ? 'Technique' : 'Technical' }}>
              <li>{lang === 'fr' ? 'Technique' : 'Technical'}</li>
            </NavLink>
          </ul>
        </li>
        )}
        {promoExcess
          && (
          <li className={styles.menuParent}>
            <NavLink to="/offre-promo-excess">{lang === 'fr' ? 'Offre My Excess' : 'My Excess Offer'}</NavLink>
          </li>
          )}
        {/* -------- première possibilité ----------- */}
        {/* Affiche le nom du bateau selectionné dans la barre de Nav (prends trop de place en mode responsive) */}
        {/* <li className="d-flex a-i-center">
          <i className="fa-solid fa-anchor mr-5" />
          <div className={styles.boatInfo}>
            <small className="mr-5">{boatSelect ? boatSelect.model : 'Pas de yacht'}</small>
            <small className={styles.boatName}>{boatSelect ? boatSelect.name : 'sélectionné'}</small>
          </div>
        </li> */}

        {/* -------- deuxieme possibilité ----------- */}
        {/* Affiche le nom du bateau selectionné dans la barre de Nav (prends trop de place en mode responsive) */}
        {/* <ul className={styles.menu2}>
          <li>
            <i className="fa-solid fa-anchor" />
            <div className={styles.boatInfo}>
              <small>{boatSelect ? boatSelect.model : 'Pas de yacht'}</small>
              <small className={styles.boatName}>{boatSelect ? boatSelect.name : 'sélectionné'}</small>
            </div>
          </li>
        </ul> */}

        {/* ---------- Affiche le menu Allset ----------- */}
        {/* <li className={styles.menuParent}>
          Allset
          <ul className={`${styles.subMenu} ${styles.subMenuTwoWidth} ${user.agent ? styles.headerPro : ''}`}>
            <NavLink to={{ pathname: '/allset' }}>
              <li>À propos</li>
            </NavLink>
            <NavLink to={{ pathname: '/team' }}>
              <li>L&apos;équipe</li>
            </NavLink>
          </ul>
        </li> */}
      </ul>
      <NavLink to={boatSelect ? '/top-sales' : '/'}>
        <img className={styles.logoAllset} src={imagesOfBrand(brand, 'headerLogoAllset').toString()} alt="logo allset" />
      </NavLink>
      <ul className={styles.menu2}>
        { boatSelect
        && (
        <NavLink to="/cart">
          <li>
            {quantityPacks > 0
            && (
            <div className={styles.containerNumbersCart}>
              <span className={`${numberCartPadding} ${animeCart}`}>{quantityPacks}</span>
            </div>
            )}
            <i className="fa-solid fa-basket-shopping" />
            <small>
              (
              {cart ? cart.totalHt : 0}
              {' '}
              € HT)
            </small>
          </li>
        </NavLink>
        )}
        <NavLink to={{ pathname: '/account' }}>
          <li className={styles.user}>
            <i className="fa-solid fa-user" />
            <small>
              {`${user.firstName} ${user.name}`}
            </small>
          </li>
        </NavLink>
        <li className={styles.icons}>
          <div onClick={() => setLang('en')}>
            <img className={styles.iconFlag} src={logoFlagEn} alt="icone de choix langue anglaise" />
          </div>
        </li>
        <li className={styles.icons}>
          <div onClick={() => setLang('fr')}>
            <img className={styles.iconFlag} src={logoFlagFr} alt="icone de choix langue française" />
          </div>
        </li>
        <li className={styles.user}>
          <i className="fa-solid fa-right-from-bracket" />
          <small onClick={() => disconnect()}>
            {lang === 'fr' ? 'Me déconnecter' : 'Logout'}
          </small>
        </li>
        {/* --------- Affiche le bateau sélectionné dans la nav a droite ------------ */}
        {/* <NavLink to="/">
          <li>
            <i className="fa-solid fa-anchor" />
            <div className={styles.boatInfo}>
              <small>{boatSelect ? boatSelect.model : 'Pas de yacht'}</small>
              <small className={styles.boatName}>{boatSelect ? boatSelect.name : 'sélectionné'}</small>
            </div>

          </li>
        </NavLink> */}
      </ul>
    </header>
  );
}

export default Web;
