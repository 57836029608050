import { useRecoilValue } from 'recoil';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import Mobile from './components/Mobile/Mobile';
import Web from './components/Web/Web';
import { languageSelected, offreExcess, userConnected } from '../../recoil';
import image from '../../assets/images/dev/header_img.svg';
import CompteurExcessPromo from './components/CompteurExcessPromo';

function Header() {
  const user = useRecoilValue(userConnected);
  const lang = useRecoilValue(languageSelected);
  const promoExcess = useRecoilValue(offreExcess);
  const navigate = useNavigate();

  return (
    <div className={styles.headerWrap}>
      {window.location.hostname.split('.')[0] === 'prestigetest'
        ? <div style={{ backgroundImage: `url(${image})` }} className={styles.warningTest} />
        : window.location.hostname.split('.')[0] === 'test'
          ? <div style={{ backgroundImage: `url(${image})` }} className={styles.warningTest} />
          : ''}
      {user.agent && <div className={styles.warningPro}>{lang === 'fr' ? 'Accès pro' : 'Pro access'}</div>}
      {promoExcess && (
        <>
          <div className={styles.HeaderPromo}>
            <p className={styles.HeaderPromoText}>
              {lang === 'fr' ? 'Offre spéciale My Excess ' : 'My Excess Special Offer '}
            </p>
            <p className={styles.HeaderPromoTextSmartphone}>
              {lang === 'fr' ? 'Offre My Excess ' : 'My Excess Offer '}
            </p>
            <CompteurExcessPromo />
            <NavLink to="/offre-promo-excess">
              <p className={styles.btnActionPromo}>
                {lang === 'fr' ? "Voir l'offre" : 'See offer'}
              </p>
            </NavLink>
          </div>
          <div onClick={() => navigate('/offre-promo-excess')} className={styles.HeaderPromoSmartphone}>
            <p className={styles.HeaderPromoText}>
              {lang === 'fr' ? 'Offre spéciale Club Excess ' : 'Excess Club Special Offer '}
            </p>
            <p className={styles.HeaderPromoTextSmartphone}>
              {lang === 'fr' ? 'Offre Club Excess ' : 'Excess Club Offer '}
            </p>
            <CompteurExcessPromo />
          </div>
        </>
      )}

      <Mobile />
      <Web />
    </div>

  );
}

export default Header;
