// Permet de set la brand qui est envoyé par la suite à la méthode images
// pour la gestion des images dans chaque composant
// Ajouter dans de le switch les marques pour lesquelles il y a une gestion des images
export function choiceBrand(brand) {
  if (brand) {
    switch (brand.toLowerCase()) {
      case 'prestige':
        return 'prestige';
      case 'lagoon':
        return 'lagoon';
      case 'excess':
        return 'excess';
      default:
        return 'all-set';
    }
  } else {
    return 'all-set';
  }
}

// permet de set la brand pour la gestion du css selon la marque
// ajouter dans le switch les différentes marques et leur classe css présente dans leur fichier respectif
export function brandChangeCss(brand) {
  if (brand) {
    switch (brand.toLowerCase()) {
      case 'prestige':
        return 'prestige_brand';
      case 'lagoon':
        return 'lagoon_brand';
      case 'excess':
        return 'excess_brand';
      default:
        return 'allset_brand';
    }
  } else {
    return 'allset_brand';
  }
}
