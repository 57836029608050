import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styles from './Mobile.module.scss';
import { boatSelected, brandSelected, languageSelected, numbersPacksInCart, offreExcess, resumeCart, userConnected } from '../../../../recoil';
import { logout } from '../../../../utils/Auth';
import logoFlagEn from '../../../../assets/images/global/Logo/en_flag.png';
import logoFlagFr from '../../../../assets/images/global/Logo/fr_flag.png';
import imagesOfBrand from '../../../../utils/images';

function Mobile() {
  const [boatSelect, setBoatSelected] = useRecoilState(boatSelected);
  const setUser = useSetRecoilState(userConnected);
  const setResumeCart = useSetRecoilState(resumeCart);
  const [showMenu, setShowMenu] = useState(false);
  const user = useRecoilValue(userConnected);
  const boat = useRecoilValue(boatSelected);
  const cart = useRecoilValue(resumeCart);
  const [lang, setLang] = useRecoilState(languageSelected);
  const brand = useRecoilValue(brandSelected);
  const quantityPacks = useRecoilValue(numbersPacksInCart);
  const [animeCart, setAnimeCart] = useState(styles.numbersCart);
  const [numberCartPadding, setnumberCartPadding] = useState('');
  const promoExcess = useRecoilValue(offreExcess);

  function disconnect() {
    logout();
    setBoatSelected(null);
    setResumeCart(null);
    setUser(null);
  }

  useEffect(() => {
    if (quantityPacks > 0 && quantityPacks < 2) {
      setnumberCartPadding(styles.numbersCartPaddingOne);
    } else if (quantityPacks > 1 && quantityPacks < 10) {
      setnumberCartPadding(styles.numbersCartPaddingTwo);
    } else {
      setnumberCartPadding(styles.numbersCartPaddingThree);
    }
    setAnimeCart(styles.numbersCartInsert);
    const timeout = setTimeout(() => {
      setAnimeCart(styles.numbersCart);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [quantityPacks]);

  return (
    <header className={styles.headerXs}>
      {/* {brand !== 'all-set'
        ? (brand !== 'localhost'
          ? (brand !== 'test'
        && (
        <NavLink to={boat ? '/top-sales' : '/'}>
          <li><img className={styles.logoBrand} src={imagesOfBrand(brand, 'headerLogoBrand').toString()} alt="logo prestige" /></li>
        </NavLink>
        )) : '') : ''} */}
      <div className={styles.menuHeader}>

        <ul className={styles.menu1}>
          <NavLink to="/" onClick={() => setShowMenu(false)}>
            <li className={styles.boatBlock}>
              <i className="fa-solid fa-anchor" />
              <div className={styles.boatInfo}>
                <small>{boat ? `${boat.brand} ${boat.boatModel}` : 'Pas de yacht'}</small>
                <small className={styles.boatName}>{boat ? boat.name : 'sélectionné' }</small>
              </div>
            </li>
          </NavLink>
        </ul>
        <NavLink to={boatSelect ? '/top-sales' : '/'}>
          <img className={styles.logoAllset} src={imagesOfBrand(brand, 'headerLogoAllset').toString()} alt="logo allset" />
        </NavLink>
        <ul className={styles.menuRight}>

          <li className={styles.icons}>
            <div onClick={() => setLang('en')}>
              <img className={styles.iconFlag} src={logoFlagEn} alt="drapeau anglais" />
            </div>
            <div onClick={() => setLang('fr')}>
              <img className={styles.iconFlag} src={logoFlagFr} alt="drapeau français" />
            </div>
          </li>
          {boat
          && (
          <NavLink to="/cart" onClick={() => setShowMenu(false)}>
            <li>
              {quantityPacks > 0
              && (
              <div className={styles.containerNumbersCart}>
                <span className={`${numberCartPadding} ${animeCart}`}>{quantityPacks}</span>
              </div>
              )}
              <i className="fa-solid fa-basket-shopping" />
              <small>
                (
                {cart ? cart.totalHt : 0}
                {' '}
                € HT)
              </small>
            </li>
          </NavLink>
          )}
          <li><i className={showMenu ? `fa-solid fa-xmark ${styles.closeBtn}` : 'fa-solid fa-bars'} onClick={() => setShowMenu(!showMenu)} /></li>
        </ul>
      </div>

      <div className={`${styles.menu2} ${user.agent ? styles.headerPro : ''} ${promoExcess ? styles.headerPromo : ''} ${showMenu ? styles.showMenu : ''}`}>
        <div className={styles.wrap}>
          <ul>
            {boat
            && (
            <>
              <NavLink to="/" onClick={() => setShowMenu(false)}>
                <li>{lang === 'fr' ? 'Mes Yachts' : 'My Yachts'}</li>
              </NavLink>
              <li>
                <NavLink to="/top-sales" onClick={() => setShowMenu(false)}>{lang === 'fr' ? 'Produits' : 'Products'}</NavLink>
                <ul className={styles.menuChildren}>
                  <NavLink to={{ pathname: '/packs/life-on-board' }} state={{ title: lang === 'fr' ? 'Vie à bord' : 'Life on board' }} onClick={() => setShowMenu(false)}>
                    <li>{lang === 'fr' ? 'Vie à bord' : 'Life on board'}</li>
                  </NavLink>
                  <NavLink to={{ pathname: '/packs/leisure' }} state={{ title: lang === 'fr' ? 'Loisirs' : 'Leisure' }} onClick={() => setShowMenu(false)}>
                    <li>{lang === 'fr' ? 'Loisirs' : 'Leisure'}</li>
                  </NavLink>
                  <NavLink to={{ pathname: '/packs/technical-equipment' }} state={{ title: lang === 'fr' ? 'Technique' : 'Technical' }} onClick={() => setShowMenu(false)}>
                    <li>{lang === 'fr' ? 'Technique' : 'Technical'}</li>
                  </NavLink>
                </ul>
              </li>
            </>
            )}
            {promoExcess
              && (
              <li>
                <NavLink to="/offre-promo-excess">{lang === 'fr' ? 'Offre My Excess' : 'My Excess Offer'}</NavLink>
              </li>
              )}
            <NavLink to={{ pathname: '/account' }} onClick={() => setShowMenu(false)}>
              <li>{lang === 'fr' ? 'Mon compte' : 'My account'}</li>
            </NavLink>
          </ul>
          <ul>
            {user.agent ? (
              <NavLink to="orders&commissions" onClick={() => setShowMenu(false)}>
                <li className={styles.orders}>
                  {lang === 'fr' ? 'Commandes' : 'Orders'}
                  {' '}
                  <span>&amp; Commissions</span>

                </li>
              </NavLink>
            ) : (
              <NavLink to="orders" onClick={() => setShowMenu(false)}>
                <li>{lang === 'fr' ? 'Mes commandes' : 'My orders'}</li>
              </NavLink>
            )}
            {/* <li>
              Allset
              <ul className={styles.menuChildren}>
                <NavLink to="/allset" onClick={() => setShowMenu(false)}>
                  <li>À propos</li>
                </NavLink>
                <NavLink to="/team" onClick={() => setShowMenu(false)}>
                  <li>L&apos;équipe</li>
                </NavLink>
              </ul>
            </li> */}
            <NavLink to="/contact" onClick={() => setShowMenu(false)}>
              <li>Contact</li>
            </NavLink>
            <li>Faq</li>
            <li><div onClick={disconnect}>{lang === 'fr' ? 'Déconnexion' : 'Logout'}</div></li>

          </ul>
        </div>
      </div>
    </header>
  );
}

export default Mobile;
