import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.scss';
import { RecoilRoot } from 'recoil';
import reportWebVitals from './reportWebVitals';
import MyRoutes from './MyRoutes';

// Conditionner en fonction des chantiers
// switch (window.location.hostname.split('.')[0]) {
//   case 'localhost':
//     import('./assets/styles/brands/_allsetVariables.scss');
//     break;
//   case 'all-set':
//     import('./assets/styles/brands/_allsetVariables.scss');
//     break;
//   case 'test':
//     import('./assets/styles/brands/_allsetVariables.scss');
//     break;
//   case 'prestige':
//     import('./assets/styles/brands/_prestigeVariables.scss');
//     break;
//   case 'prestigetest':
//     import('./assets/styles/brands/_prestigeVariables.scss');
//     break;
//   default:
//     import('./assets/styles/brands/_allsetVariables.scss');
//     break;
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <MyRoutes />
  </RecoilRoot>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//
