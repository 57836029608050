import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import styles from './CompteurExcessPromo.module.scss';
import { dateMaxOffreExcess, languageSelected } from '../../../recoil';

function CompteurExcessPromo() {
  const dateMaxOffre = useRecoilValue(dateMaxOffreExcess);
  const lang = useRecoilValue(languageSelected);

  function calculerTempsRestant(dateLimite) {
    const maintenant = new Date();
    const differenceEnMillisecondes = dateLimite - maintenant;

    let jours = Math.floor(differenceEnMillisecondes / (1000 * 60 * 60 * 24));
    let heures = Math.floor((differenceEnMillisecondes % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((differenceEnMillisecondes % (1000 * 60 * 60)) / (1000 * 60));
    let secondes = Math.floor((differenceEnMillisecondes % (1000 * 60)) / 1000);

    if (jours < 0) {
      jours = Math.floor(differenceEnMillisecondes / (1000 * 60 * 60 * 24) + 1);
      heures = Math.floor((differenceEnMillisecondes % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + 24);
      minutes = Math.floor((differenceEnMillisecondes % (1000 * 60 * 60)) / (1000 * 60) + 60);
      secondes = Math.floor((differenceEnMillisecondes % (1000 * 60)) / 1000 + 60);
    }

    return {
      jours,
      heures,
      minutes,
      secondes,
    };
  }
  const [tempsRestant, setTempsRestant] = useState(calculerTempsRestant(dateMaxOffre));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const tempsRestantActuel = calculerTempsRestant(dateMaxOffre);
      setTempsRestant(tempsRestantActuel);
    }, 1000); // Mettez à jour toutes les secondes

    return () => clearInterval(intervalId); // Nettoyer l'intervalle lors du démontage du composant
  }, [dateMaxOffre]);

  return (
    <div>
      <p className={styles.blocCompteur}>
        {' '}
        {tempsRestant.jours}
        {' '}
        {lang === 'fr' ? 'jours' : 'days'}
        {' '}
        {tempsRestant.heures}
        {' '}
        {lang === 'fr' ? 'heures' : 'hours'}
        {' '}
        {tempsRestant.minutes}
        {' '}
        minutes
        {' '}
        {tempsRestant.secondes}
        {' '}
        {lang === 'fr' ? 'secondes' : 'seconds'}
      </p>
      <p className={styles.blocCompteurSmartphone}>
        {' '}
        {tempsRestant.jours}
        {' '}
        {lang === 'fr' ? 'j' : 'd'}
        {' '}
        {tempsRestant.heures}
        {' '}
        h
        {' '}
        {tempsRestant.minutes}
        {' '}
        m
        {' '}
        {tempsRestant.secondes}
        {' '}
        s
      </p>
    </div>
  );
}

export default CompteurExcessPromo;
