import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { boatSelected, languageSelected, userConnected } from '../../recoil';
import styles from './Footer.module.scss';

function Footer() {
  const boat = useRecoilValue(boatSelected);
  const user = useRecoilValue(userConnected);
  const lang = useRecoilValue(languageSelected);

  // Partie Newsletter, penser à remettre les imports  userConnected / useState
  // const user = useRecoilValue(userConnected);
  // const [displayModal, setDisplayModal] = useState(false);
  return (
    <div className={styles.footer}>

      {/* BANDEAU NEWSLETTER DESACTIVÉ POUR LE MOMENT */}
      {/* {!user.newsletter
        ? (
          <>
            <div className={styles.newsletter}>
              <span onClick={() => setDisplayModal(true)}>Inscrivez-vous à la newsletter</span>
            </div>
            <FooterNewsletterModal displayModal={displayModal} setDisplayModal={setDisplayModal} />
          </>
        )
        : (
          <hr className={styles.hrMarginBottom} />
        ) } */}
      <hr className={styles.hrMarginBottom} />
      <div className={`${styles.containerList} ${styles.containerFooter}`}>
        <div className={styles.about}>
          {/* <ul>
            <li className={styles.title}>{lang === 'fr' ? 'À propos' : 'About'}</li>
            <ul>
              <NavLink to={{ pathname: '/allset' }}>
                <li>All Set</li>
              </NavLink>
              <NavLink to={{ pathname: '/team' }}>
                <li>L&apos;équipe</li>
              </NavLink>
            </ul>
          </ul> */}
        </div>
        <div className={styles.account}>
          <ul>
            <li className={styles.title}>{lang === 'fr' ? 'Mon compte' : 'My account'}</li>
            <ul>
              <NavLink to={{ pathname: '/account' }}>
                <li>{lang === 'fr' ? 'Mon compte' : 'My account'}</li>
              </NavLink>
              {boat
              && (
              <li>
                <NavLink to={{ pathname: '/cart' }}>
                  {lang === 'fr' ? 'Panier' : 'Basket'}
                </NavLink>
              </li>
              )}
              {boat
              && (
                user.agent
                  ? (
                    <NavLink to={{ pathname: '/orders&commissions' }}>
                      <li>{lang === 'fr' ? 'Mes commandes' : 'My orders'}</li>
                    </NavLink>
                  )
                  : (
                    <NavLink to={{ pathname: '/orders' }}>
                      <li>{lang === 'fr' ? 'Mes commandes' : 'My orders'}</li>
                    </NavLink>
                  )
              )}
            </ul>
          </ul>
        </div>
        <div className={styles.service}>
          <ul>
            <li className={styles.title}>{lang === 'fr' ? 'Service client' : 'Customer service'}</li>
            <ul>
              <li>FAQ</li>
              <NavLink to={{ pathname: '/contact' }}>
                <li>Contact</li>
              </NavLink>
              <li>{lang === 'fr' ? 'Retour & remboursements' : 'Returns & refunds'}</li>
              <li>{lang === 'fr' ? 'Termes & conditions' : 'Terms & conditions'}</li>
            </ul>
          </ul>
        </div>
      </div>
      <hr />
      <div className={`${styles.containerLinks} ${styles.containerFooter}`}>
        <p className={styles.copyright}>
          &copy; All Set by Yacht Solutions
          {' '}
          <span>{lang === 'fr' ? 'Mentions légales' : 'Legal notice'}</span>
        </p>
        {/* LIENS RESEAUX SOCIAUX */}
        {/* <div className={styles.media}>
          <p>Follow Us</p>
          <div>
            <i className="fa-brands fa-instagram" />
            <i className="fa-brands fa-linkedin-in" />
            <i className="fa-brands fa-facebook-f" />
            <i className="fa-brands fa-youtube" />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Footer;
