import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { BASE_URL_API, boatSelected, notifcation, error, resumeCart, userConnected, languageSelected, numbersPacksInCart, brandSelected, offreExcess, dateMaxOffreExcess, promoExcessActive } from '../../../../../recoil';
import { getFetchAuthorization } from '../../../../../utils/Auth';
import styles from './BoatMenuNav.module.scss';
import { choiceBrand } from '../../../../../utils/Brand';
import { getDateLimiteOffreExcess, isLivraisonPlusDeTroisMois } from '../../../../../utils/Helpers';

function BoatMenuNav({ boat }) {
  const [boatAtom, setBoatSelected] = useRecoilState(boatSelected);
  const setResumeCart = useSetRecoilState(resumeCart);
  const setError = useSetRecoilState(error);
  const user = useRecoilValue(userConnected);
  const URL_API = useRecoilValue(BASE_URL_API);
  const setNotification = useSetRecoilState(notifcation);
  const navigate = useNavigate();
  const lang = useRecoilValue(languageSelected);
  const setQuantityPacks = useSetRecoilState(numbersPacksInCart);
  const setBrand = useSetRecoilState(brandSelected);
  const setOffreExcess = useSetRecoilState(offreExcess);
  const setDateMaxOffreExcess = useSetRecoilState(dateMaxOffreExcess);
  const promotionExcessActive = useRecoilValue(promoExcessActive);

  async function selectBoat() {
    setBoatSelected({
      id: boat.bateau.id,
      projetId: boat.id,
      model: boat.bateau.nom,
      name: boat.nom,
      worksiteOrderNumber: boat.numero_commande_bateau_allset,
      nameOwner: boat.nom_proprietaire,
      voltage: boat.voltage,
      photo: boat.bateau.photo_allset,
      brand: boat.allset_brand_name,
      brandId: boat.allset_brand_id,
      boatModel: boat.allset_model_name,
      boatModelId: boat.allset_model_id,
      hullNumber: boat.n_coque,
      deliveryDate: boat.date_livraison,
      promo_allset_utilise: boat.promo_allset_utilise,
    });

    // Select le projet et créé ou reprend une commande en cours
    try {
      const response = await fetch(`${URL_API}/saveProjectSelected`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getFetchAuthorization(),
        },
        body: JSON.stringify({ boatId: boat.id, userId: user.id }),
      });

      const data = await response.json();

      if (response.ok) {
        setResumeCart(data.resumeCart);
        if (data.resumeCart.quantityPacks) {
          setQuantityPacks(data.resumeCart.quantityPacks);
        } else {
          setQuantityPacks(0);
        }
        setNotification({
          type: 'success',
          text: lang === 'fr' ? `Yacht: ${boat.nom} sélectionné` : `Yacht: ${boat.nom} selected`,
        });
      } else {
        setError({ status: response.status, errorText: response.statusText, msg: data.msg });
      }
    } catch (e) {
      setError({ info: e });
    }
  }

  function shop() {
    selectBoat();
    setBrand(choiceBrand(boat.allset_brand_name));
    navigate('/top-sales');
    if (boat.allset_brand_name.toLowerCase() === 'excess' && isLivraisonPlusDeTroisMois(boat.date_livraison) && promotionExcessActive) {
      setOffreExcess(true);
      const dateMaxOffre = getDateLimiteOffreExcess(boat.date_livraison);
      setDateMaxOffreExcess(dateMaxOffre);
    } else {
      setOffreExcess(false);
    }
  }
  return (
    <div className={styles.blocBoat} onClick={shop}>
      <div className={styles.blocImageModel}>
        <img className={styles.imageBoat} src={boat.bateau.photo_allset} alt="yacht" />
        <div>
          <p className={styles.boatNom}>
            {boat.allset_brand_name}
            {' '}
            {boat.allset_model_name}
            {boat.n_coque && ` #${boat.n_coque}`}
          </p>
          <p>{boat.nom ? boat.nom : ''}</p>
        </div>
      </div>
    </div>
  );
}

export default BoatMenuNav;
