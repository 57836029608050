import { NavLink, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { boatSelected, brandSelected, languageSelected, resumeCart, userConnected } from '../../recoil';
import { logout } from '../../utils/Auth';
import styles from './Aside.module.scss';

function Aside() {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userConnected);
  const setResumeCart = useSetRecoilState(resumeCart);
  const setBoatSelected = useSetRecoilState(boatSelected);
  const boat = useRecoilValue(boatSelected);
  const lang = useRecoilValue(languageSelected);
  const brand = useRecoilValue(brandSelected);

  function disconnect() {
    logout();
    setBoatSelected(null);
    setResumeCart(null);
    setUser(null);
    navigate('/');
  }

  const handleButtonClick = () => {
    let url = '';
    if (lang === 'fr') {
      url = 'https://myexcess.excess-catamarans.com//';
    } else {
      url = 'https://myexcess.excess-catamarans.com/en';
    }
    window.open(url, '_blank');
  };

  return (
    <div className={styles.btnGroup}>
      {boat
      && (
      <button onClick={() => (boat ? navigate('/top-sales') : navigate('/'))} className={styles.btnSecondary}>
        <span className={styles.btnSecondaryBoatName}>
          {boat.brand}
          {' '}
          {boat.boatModel}
          {boat.hullNumber && ` #${boat.hullNumber}`}
        </span>
        {boat.name
        && (
        <span className={styles.btnSecondaryBoatModel}>
          {' '}
          -
          {' '}
          {boat.name}
        </span>
        )}
      </button>
      )}
      <button onClick={() => (user && user.agent ? navigate('/orders&commissions') : navigate('/orders'))} className={styles.btnFirst}>{user && user.agent ? (lang === 'fr' ? 'Tableau de bord' : 'Dashboard') : (lang === 'fr' ? 'Mes commandes' : 'My orders')}</button>
      {/* <button onClick={() => (user && user.agent ? navigate('/orders&commissions') : navigate('/orders'))} className={styles.btnFirst}>{(lang === 'fr' ? 'Mes commandes' : 'My orders')}</button> */}
      <NavLink to="/contact">
        <button className={styles.btnOther}>Contact</button>
      </NavLink>
      <NavLink to={{ pathname: '/faq' }}>
        <button className={styles.btnOther}>FAQ</button>
      </NavLink>
      {brand === 'excess'
        && (
        <button onClick={handleButtonClick} className={styles.btnOther}>
          My Excess
        </button>
        )}
      {/* <button onClick={disconnect} className={styles.btnOther}>Me déconnecter</button> */}
    </div>
  );
}

export default Aside;
