export function formatPrice(price) {
  return price.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, separator: ' ' });
}

export function calcPriceTTC(priceHt) {
  const resultat = priceHt + (priceHt * (20 / 100));
  return formatPrice(resultat);
}

export function calcCommission(totalHt) {
  const resultat = totalHt * (10 / 100);
  return formatPrice(resultat);
}

export function calcTVA(totalHt) {
  const resultat = totalHt * (20 / 100);
  return formatPrice(resultat);
}

export function displayOrderStatus(status) {
  switch (status) {
    case 'validée':
      return 'En attente de traitement';

    case 'en production':
      return 'En cours de traitement';

    case 'terminée':
      return 'Expédiée';

    default:
      return false;
  }
}

export function categoryShortName(categoryName) {
  switch (categoryName) {
    case 'Equipement technique':
      return 'Technique';

    case 'Technical equipment':
      return 'Technical';

    case 'Vie à bord':
      return 'Vie à bord';

    case 'Life on board':
      return 'Life on board';

    case 'Loisirs':
      return 'Loisirs';

    case 'Leisure':
      return 'Leisure';

    default:
      return false;
  }
}

export function afficherDate(date) {
  // Récupérer les composants de la date
  let jour = date.getDate();
  let mois = date.getMonth() + 1; // Les mois sont indexés à partir de 0
  const annee = date.getFullYear();
  let heures = date.getHours();
  let minutes = date.getMinutes();
  let secondes = date.getSeconds();

  // Ajouter un zéro devant les chiffres de 1 à 9
  if (jour < 10) {
    jour = `0${jour}`;
  }
  if (mois < 10) {
    mois = `0${mois}`;
  }
  if (heures < 10) {
    heures = `0${heures}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (secondes < 10) {
    secondes = `0${secondes}`;
  }

  // Retourner la date formatée
  return `${jour}/${mois}/${annee}:${heures}:${minutes}:${secondes}`;
}

export function isLivraisonPlusDeTroisMois(date) {
  const dateLivraison = new Date(date);
  const dateActuelle = new Date();

  // Calcul de la différence en millisecondes
  const differenceEnMillisecondes = dateLivraison - dateActuelle;

  // Conversion de la différence en mois
  const differenceEnMois = differenceEnMillisecondes / (1000 * 60 * 60 * 24 * 30);

  // Si la différence est supérieure à 3 mois, retourne true, sinon false
  return differenceEnMois > 3;
}

export function getDateLimiteOffreExcess(dateDeLivraison) {
  const dateLivraison = new Date(dateDeLivraison);
  const dateLimite = new Date(dateLivraison);

  // Soustrait 3 mois à la date de livraison
  dateLimite.setMonth(dateLimite.getMonth() - 3);

  return dateLimite;
}

export function formatDateFrench(date) {
  const day = (`0${date.getDate()}`).slice(-2);
  const month = (`0${date.getMonth() + 1}`).slice(-2); // Les mois commencent à 0, donc on ajoute 1
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function formatDateEnglish(date) {
  return date.toLocaleDateString('en-US'); // Utilisez 'en-GB' pour le format britannique
}

export function formatLivraisonDate(date, lang) {
  const dateString = date;
  const dateObject = new Date(dateString);

  // Options de formatage pour la date
  const options = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };

  let formattedDate = '';
  if (lang === 'fr') {
    // Conversion de la date en format désiré
    formattedDate = dateObject.toLocaleDateString('fr-FR', options);
  } else {
    formattedDate = dateObject.toLocaleDateString('en-US', options);
  }

  return formattedDate;
}
