export function saveToken(token, stayLogged = false) {
  if (stayLogged) {
    localStorage.setItem('token', token);
  } else {
    sessionStorage.setItem('token', token);
  }
}

export function getToken() {
  if (localStorage.getItem('token')) {
    return localStorage.getItem('token');
  }
  if (sessionStorage.getItem('token')) {
    return sessionStorage.getItem('token');
  }
  return null;
}

export function getFetchAuthorization() {
  return `Bearer ${getToken()}`;
}

export function logout() {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');
}
