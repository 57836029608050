import { NavLink } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styles from './HeaderUnlogged.module.scss';
import { brandSelected, languageSelected } from '../../recoil';
import logoFlagEn from '../../assets/images/global/Logo/en_flag.png';
import logoFlagFr from '../../assets/images/global/Logo/fr_flag.png';
import imagesOfBrand from '../../utils/images';

function HeaderUnlogged() {
  const [lang, setLang] = useRecoilState(languageSelected);
  const brand = useRecoilValue(brandSelected);

  return (
    <div className={styles.headerWrap}>
      {/* Header Web */}
      <header className={styles.header}>
        <ul className={styles.menu1}>
          <li>
            {brand !== 'all-set'
              ? (brand !== 'localhost'
                ? (brand !== 'test'
              && <img className={styles.logo1} src={imagesOfBrand(brand, 'headerUnloggedLogoBrand').toString()} alt="logo prestige" />) : '') : ''}
          </li>
        </ul>
        <NavLink to="/">
          <img className={styles.logoAllset} src={imagesOfBrand(brand, 'headerUnloggedLogoAllset')} alt="logo allset" />
        </NavLink>
        <ul className={styles.menu2}>
          <NavLink to="/contact">
            <li>Contact</li>
          </NavLink>
          <li className={styles.icons}>
            <div onClick={() => setLang('en')}>
              <img className={styles.iconFlag} src={logoFlagEn} alt="logo allset" />
            </div>
          </li>
          <li className={styles.icons}>
            <div onClick={() => setLang('fr')}>
              <img className={styles.iconFlag} src={logoFlagFr} alt="logo allset" />
            </div>
          </li>
        </ul>
      </header>
    </div>

  );
}

export default HeaderUnlogged;
