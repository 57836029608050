import { useRecoilValue } from 'recoil';
import { languageSelected } from '../../recoil';
import styles from './FooterUnlogged.module.scss';

function FooterUnlogged() {
  const lang = useRecoilValue(languageSelected);
  return (
    <div className={styles.footer}>
      <hr />
      <div className={`${styles.containerLinks} ${styles.containerFooter}`}>
        <p className={styles.copyright}>
          &copy; All Set by Yacht Solutions
          {' '}
          <span>{lang === 'fr' ? 'Mentions légales' : 'Legal notice'}</span>
        </p>
        {/* <div className={styles.media}>
          <p>Follow Us</p>
          <div>
            <i className="fa-brands fa-instagram" />
            <i className="fa-brands fa-linkedin-in" />
            <i className="fa-brands fa-facebook-f" />
            <i className="fa-brands fa-youtube" />
            <i className="fa-brands fa-mailchimp" />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default FooterUnlogged;
